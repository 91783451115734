export enum TournamentPreviewImage {
    easter_egg_hunt = 'img/tournaments/preview_easter_egg_hunt.jpg',
    egg_hunt_map = 'img/tournaments/preview_egg_hunt_map.png',
    golden_bunny = 'img/tournaments/preview_golden_bunny.png',
    golden_bunny2 = 'img/tournaments/preview_golden_bunny2.png',
    march_madness = 'img/tournaments/preview_march_madness.png',
    pot_gold = 'img/tournaments/preview_pot_gold.png',
    spring_frenzy = 'img/tournaments/preview_spring_frenzy.png',
    clover_clash = 'img/tournaments/preview_clover_clash.png',
    Poker_14Feb_Tournament = 'img/tournaments/preview_poker_14feb_tournament.png',
    XMass_Rush_Tournaments = 'img/tournaments/preview_xmas_rush.jpg',
    No_Joke_Tournament = 'img/tournaments/preview_no_joke_tournament.jpg',
    Royal_Slots_Tournament = 'img/tournaments/preview_royal_slots_tournament.jpg',
    NY_Jackpot_Tournament = "img/tournaments/preview_ny_jackpot_tournament.jpg",
    Super_Bowl_Tournament = "img/tournaments/preview_super_bowl_tournament.jpg",
    Xmas_Poker_v1 = "img/tournaments/preview_poker_ny_v1_tournament.jpg",
    Xmas_Poker_v2 = "img/tournaments/preview_poker_ny_v2_tournament.jpg",
    Poker = "img/tournaments/preview_poker_tournament.jpg",
    WC_Bronze = "img/tournaments/preview_wc_bronze.jpg",
    WC_Silver = "img/tournaments/preview_wc_silver.jpg",
    WC_Gold = "img/tournaments/preview_wc_gold.jpg",
    Jackpot_Tournament = "img/tournaments/preview_jackpot_tournament.jpg",
    VIP_Tournament = "img/tournaments/preview_vip_tournament.jpg",
    Flash_Tournament = "img/tournaments/preview_flash_tournament.jpg",
    Diamond_Tournament = "img/tournaments/preview_diamond_tournament.jpg",
    Gold_Tournament = "img/tournaments/preview_gold_tournament.jpg",
    Silver_Tournament = "img/tournaments/preview_silver_tournament.jpg",
    Bronze_Tournament = "img/tournaments/preview_bronze_tournament.jpg",
    BlackFriday = "img/tournaments/preview_blackfriday.png",
    ThanksGiving = "img/tournaments/preview_thanksgiving_day.png",
    July4 = "img/tournaments/preview_4july.jpg"
}